import * as React from 'react'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import { PrismicRichText } from '@prismicio/react'

import * as styles from './Article.module.scss'
import * as bodyStyles from '../slices/ArticleSlices.module.scss'

const LegalPage = ({ data }: PageProps<Queries.LegalPageQuery>) => {
  if (!data || !data.allPrismicLegalPage || data.allPrismicLegalPage.nodes.length < 1)
    return null
  const doc = data.allPrismicLegalPage.nodes[0].data

  return (
    <Layout>
      <div className={styles.article}>
        <div className={styles.articleContainer}>
          <div className={styles.articleHeader}>
            <h1>{doc.title?.text}</h1>
          </div>
          <div className={styles.articleBody}>
            <section className={`${bodyStyles.slice} ${bodyStyles.text}`}>
              <PrismicRichText field={doc.content?.richText}/>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query LegalPage($slug: String) {
    allPrismicLegalPage(
      filter: { data: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        data {
          slug
          title {
            text
          }
          content {
            richText
          }
        }
      }
    }
  }
`

export default withPrismicPreview(LegalPage)
